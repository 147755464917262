<template>
	<div>
		<Principal v-bind="principal"></Principal>
		<Secundario v-bind="about"></Secundario>
		<Features v-bind="feature"></Features>
	</div>
</template>

<script>
import Principal from '../components/Principal.vue';
import Secundario from '../components/Secundario.vue';
import Features from '../components/Features.vue';

export default {
	components: {
		Principal,
		Secundario,
		Features,
	},

	data() {
		return {
			principal: {
				titulo_1: 'Bienvenidos a',
				titulo_2: 'DIMAM',
				imagen: 'https://cdn.pixabay.com/photo/2016/11/23/14/02/close-up-1853057_960_720.jpg',
				goto: '#about-me',
			},
			about: {
				titulo: 'QUIENES SOMOS',
				texto: `Somos una empresa con mas de 30 años de experiencia en el área de la
        industria metal-mecánica. Nos especializamos en el área de mantenimiento
        hidráulico para maquinaria pesada y suministramos toda clase de
        repuestos para estos equipos.`,
				avatar: 'http://dimamsas.com/wp-content/uploads/2019/10/retro-e1570473131268.png',
				goto: '#features',
			},
			feature: {
				titulo: 'SECTORES',
				features: [
					{
						icon: 'agriculture',
						subtitle: 'Agricultura',
						text:
							'Reparación de todo tipo de maquinaria usada en la Industria de la Agricultura.',
						color: 'success',
					},
					{
						icon: 'handyman',
						subtitle: 'Construccion',
						text:
							'Maquinaria amarilla, y maquinaria pesada usada en la industria de la construcción.',
						color: 'error',
					},
					{
						icon: 'local_gas_station',
						subtitle: 'Petroleo',
						text: 'Cromo-duro de tuberías de acero y ejes.',
						color: 'warning',
					},
					{
						icon: 'workspaces',
						subtitle: 'Aplicaciones Especiales',
						text: 'Aplicaciones que requieren alta dureza y resistencia al desgaste.',
						color: '#B0BEC5',
					},
				],
			},
		};
	},
};
</script>
<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
