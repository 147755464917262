<template>
	<section id="features" class="section">
		<div class="py-12"></div>

		<v-container class="text-center">
			<h2 class="display-2 font-weight-bold mb-3 white--text">{{ titulo }}</h2>

			<v-responsive class="mx-auto mb-12" width="56">
				<v-divider class="mb-1"></v-divider>

				<v-divider></v-divider>
			</v-responsive>

			<v-row>
				<v-col
					v-for="({ icon, subtitle, text, color }, i) in features"
					:key="i"
					cols="12"
					sm="6"
					md="3"
				>
					<v-card class="py-12 px-4 section" flat>
						<v-theme-provider dark>
							<div>
								<v-btn class="align-self-end" fab outlined>
									<v-icon large v-text="icon" :color="color"></v-icon>
								</v-btn>
							</div>
						</v-theme-provider>
						<v-card-title
							class="justify-center font-weight-black text-uppercase white--text"
							v-text="subtitle"
						></v-card-title>
						<v-card-text class="subtitle-1 white--text" v-text="text"> </v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<div class="py-12"></div>
	</section>
</template>

<script>
export default {
	props: ['titulo', 'features'],
};
</script>
